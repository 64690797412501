import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import server from '../../services/fetchApi';

import FarewellCard from '../../components/FarewellCard.tsx';
import { Body2 } from '../../components/Fonts/Body';
import { H4 } from '../../components/Fonts/HTags';
import { Subtitle } from '../../components/Fonts/Subtitle';
import Footer from '../../components/Footer';
import { Line } from '../../components/Line';
import NavBar from '../../components/Nav';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import Base from '../../layout/Base';

import { fontWeightType } from '../../constants/enums';

import { Button } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Seo } from '../../components/Seo';
import { sentryCaptureException } from '../../services/sentry';
import { useAuth } from '../../hooks/useAuth';

const UserInfo = styled.nav`
  background: transparent;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
`;

const PictureUser = styled.div`
  background-color: ${({ theme }) => theme.colors.mainAguaColor};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.simpleWhite};
  border-radius: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Farewell = styled.p`
  font-size: 12px;
`;

const CanceledPage = () => {
  useAuth();
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const response = await server.get('api/v1/users/get_user');
      setCurrentUser(response.data);
    } catch (error) {
      sentryCaptureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const { first_name, last_name, email } = currentUser?.user || {
    first_name: '',
    last_name: '',
    email: '',
  };

  const endDate = typeof window !== 'undefined' ? localStorage.getItem('endDate') : '';

  if (loading) {
    return (
      <Loading
        onPress={() => null}
        mainTitle="Por favor espere un momento..."
        type="loading"
        buttonLabel=""
      />
    );
  }

  return (
    <Base>
      <NavBar>
        <UserInfo>
          <PictureUser>
            {first_name[0]}
            {last_name[0]}
          </PictureUser>
          <Spacer size="8" />
          <div>
            <Subtitle color="simpleWhite" fontWeight={fontWeightType.Bold}>
              {first_name} {last_name}
            </Subtitle>
            <Spacer size="3" />
            <Body2 color="simpleWhite" fontWeight={fontWeightType.Medium}>
              {email}
            </Body2>
          </div>
        </UserInfo>
      </NavBar>
      <View>
        <Spacer size="30" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Tu suscripción ha sido cancelada con éxito
        </H4>
        <Spacer size="18" />
        <RowContainer>
          <Farewell>
            Esperamos que tu proceso de aprendizaje con nosotros haya sido provechoso y que hayas
            podido incrementar tus conocimientos de cara a tu preparación para tu residencia
            /especialización.
          </Farewell>
        </RowContainer>
        <Spacer size="18" />
        <RowContainer>
          <Farewell>
            Recuerda que podrás seguir aprovechando los beneficios de tu plan actual hasta finalizar
            tu actual periodo de facturación.
          </Farewell>
        </RowContainer>
        <Spacer size="18" />
        <FarewellCard canceled date={endDate || ''} />
        <Spacer size="35" />
        <Line />
        <Spacer size="35" />
        <CenterContainer>
          <Button
            customWidth={'85%'}
            customHeight={'44px'}
            customBg={'white'}
            onClick={() => {
              navigate(`/account`);
            }}>
            Regresar
          </Button>
        </CenterContainer>
        <Spacer size="35" />
        <Line />
        <Spacer size="35" />
      </View>
      <Footer />
    </Base>
  );
};

export default CanceledPage;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Plan" />;
